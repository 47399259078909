import React from 'react';
import { Link } from 'react-router-dom';
import { Mail } from 'react-feather';

import './_styles.css';

import storageSmallMobile from '../img/cropped-storage-small.jpg'; 
import storageLargeMobile from '../img/cropped-storage-large.jpg'; 

const Units = () => {

  const scrollToTop = () => {
    window.scrollTo(0, 0)

  }

  return (
    <div className="units paper">      
      <div className="unit-div">
        <div className="unit-picture-div">
          <img src={ storageSmallMobile } alt="Small Storage units are five by ten feet and rent for $50 a month."/>
        </div>
        <div className="unit-features-div">
          <p>Small 5x10</p>
          <p>$50/<span className="per-month">mo</span></p>
          <p><Mail size='15' color='#0f576a'/> <a className="phone" href="mailto:contact@apexjefferson.com">contact@apexjefferson.com</a></p>
        </div>
      </div>
      <div className="unit-div">
        <div className="unit-picture-div">
          <img src={ storageLargeMobile } alt="Large Storage units are ten by ten feet and rent for $90 a month."/>
        </div>
        <div className="unit-features-div">
          <p>Large 10x20</p>
          <p>$90/<span className="per-month">mo</span></p>
          <p><Mail size='15' color='#0f576a'/> <a className="phone" href="mailto:contact@apexjefferson.com">contact@apexjefferson.com</a></p>
        </div>
      </div>
    </div>
  );
}

export default Units;
