import React from 'react';

import { FilloutStandardEmbed } from "@fillout/react";
import "@fillout/react/style.css";


const RentalPayments = () => {
    return (
        <div className="default-body">
	    <FilloutStandardEmbed filloutId="aDNaPu29xDus" />
        </div>
    );
}

export default RentalPayments;
